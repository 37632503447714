<template>
  <el-form
    ref="loginForm"
    :model="form"
    :rules="rules"
    @keydown.enter="handleSubmit"
    class="form-login pass-form"
    autocomplete="off"
  >
    <!-- 假填充盒子 -->
    <div style="width:0;height:0;overflow:hidden;">
      <input type="text" name="prevent_autofill" id="prevent_autofill" value="" />
      <input type="password" name="password_fake" id="password_fake" value="" />
    </div>
    <el-form-item prop="userName">
      <el-input
        size="large"
        v-model="userInfo.phone"
        placeholder="请输入手机号"
        disabled
      ></el-input>
    </el-form-item>
    <el-form-item prop="code">
      <el-input
        size="large"
        v-model="form.code"
        placeholder="请输入验证码"
      >
        <template #suffix>
          <span class="slot-icon">
            <a
              href="javascript:void(0)"
              @click="getCode"
              :style="{ color: time <= 0 ? '' : '#ccc' }"
            >
              获取验证码
              <span v-if="time">({{ time }}s)</span>
            </a>
          </span>
        </template>
      </el-input>
    </el-form-item>
    <el-form-item prop="password">
      <el-input
        size="large"
        type="password"
        v-model="form.password"
        placeholder="请输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item prop="repassword">
      <el-input
        size="large"
        type="password"
        v-model="form.repassword"
        placeholder="请再次输入新密码"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button
        class="long-btn"
        size="large"
        @click="handleSubmit"
        type="primary"
        long
      >
        重置密码
      </el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { dataInterface } from '@/apis/data/index';
export default {
  components: {},
  props:{
    userInfo:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return {
      form: {
        userName: "",
        code: "",
        password: "",
        repassword: "",
      },
      rules: {
        code: [
          { validator: (rule, value, cb) => {
              if(!value) {
                cb(new Error('验证不能为空！'))
              } else {
                const reg = /^\d{4}$/
                if(reg.test(value)) {
                  cb()
                } else {
                  cb(new Error('验证码格式不正确'))
                }
              }
            }, 
            trigger: 'blur' 
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        repassword: [
          { validator: (rule, value, cb) => {
              if(!value) {
                cb(new Error('确认密码不能为空！'))
              } else {
                if(this.form.password !== value) {
                  cb(new Error('两次密码不一致'))
                } else {
                  cb()
                }
              }
            }, 
            trigger: 'change' 
          }
        ],
      },
      hasCode: false,
      timer: "",
      time: 0,
    }
  },
  methods:{
    handleSubmit(){
      this.$refs.loginForm.validate((valid) => {
        if(valid) {
          dataInterface({
            phone: this.userInfo.phone,
            code: this.form.code,
            password: this.form.password,
            password_confirmation: this.form.repassword
          },'api/v4/user/reset/pwd')
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success('提交成功！即将前往登陆页面')
              this.$router.push({
                name: 'Login'
              });
            }
        });
        }
      })
    },

    getCode (){
      if (this.time <= 0) {
        dataInterface({phone: this.userInfo.phone}, 'api/v4/user/reset/sendcode').then((res)=>{
          this.time = 60;
          this.countDown();
          if (res && res.status === 200) {
            this.$message.success('发送成功')
          }
        })
      }
    },
    countDown(){
      if (this.time > 0) {
        this.timer = setInterval(() => {
          this.time = this.time - 1;
          if(this.time < 1){
            clearInterval(this.timer);
            this.hasCode = false;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.hasCode = false;
      }
    },
    doBackLogin() {
      this.$emit('changeMode', 'loginForm')
    }
  }
}
</script>
<style lang="less" scoped>
@inputHeight: 34px;
.form-login {
  width: 356px;
  background: #fff;
  border-radius: 4px;
  padding-bottom: 12px;
  :deep(.el-form-item) {
    margin-top: 12px;
    padding: 0 36px;
    box-sizing: border-box;
    .el-input{
      .el-input__inner{
        height: @inputHeight;
        line-height: @inputHeight;
        font-family: 'Source Han Sans CN';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        &::placeholder{
          color: #9B9B9B;
        }
      }
    }
    .long-btn {
      width: 100%;
      height: @inputHeight;
      font-weight: 500;
      font-size: 14px;
      & + .long-btn{
        margin-left: 0;
        margin-top: 12px;
      }
    }
  }
}
.slot-icon {
  color: #a1a5ad;
  font-size: 14px;
  border-left: 1px solid #dcdee2;
  padding-left: 10px;
  line-height: 40px;
  a {
    color: #0076ff;
  }
}
</style>