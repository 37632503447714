<!--
 * @Description: 用户中心
 * @Author: luocheng
 * @Date: 2022-03-28 17:07:44
 * @LastEditors: luocheng
 * @LastEditTime: 2022-03-28 17:53:38
-->
<template>
  <div class="user-center">
    <!-- 侧边 -->
    <SideBar v-model="currentTab" :navConfig="navConfig"></SideBar>
    <article class="user-center-main">
      <h3 class="section-title">{{ title }}</h3>
      <div class="part-main-box">
        <!-- 账户信息 -->
        <AccountInfo v-if="currentTab === 'account'"></AccountInfo>
      </div>
    </article>
  </div>
</template>

<script>
import SideBar from './components/SideBar';
import AccountInfo from './components/AccountInfo';

export default {
  name: 'UserCenter',
  components: {
    SideBar,
    AccountInfo
  },
  data() {
    return {
      currentTab: 'account',
      navConfig: {
        account: {
          label: '账号信息',
          icon: 'iconyonghuzhanghu'
        },
        // info: {
        //   label: '个人资料',
        //   icon: 'iconshoucewendang'
        // },
        // preference: {
        //   label: '偏好设置',
        //   icon: 'iconshezhipeizhi'
        // }
      }
    }
  },
  computed: {
    title() {
      if (!this.currentTab) return '';
      return this.navConfig[this.currentTab]?.label || '';
    }
  }
}
</script>

<style lang="less" scoped>
.user-center{
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  .user-center-main{
    flex: 1;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .section-title{
      min-height: 78px;
      box-sizing: border-box;
      padding: 28px 40px 26px 40px;
      box-sizing: border-box;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #121619;
      line-height: 24px;
      border-bottom: 1px solid #E9EBF0;
    }
    .part-main-box{
      flex: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>